* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* anchor links */
#wedding, #weddingCouplesStay, #groomsmen, #thingsToDo, #faq {
  scroll-margin-top: 55px;
}

:root {
  text-align: center;
  font-family: Noto Sans, Roboto, Arial, sans-serif;
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;

  color-scheme: light dark;
  color: var(--color-gray-700);
  background-color: #fff;

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;

  --color-gray-100: #f4f3f1;
  --color-gray-200: #dddbd8;
  --color-gray-300: #ccc9c6;
  --color-gray-400: #aeaba7;
  --color-gray-500: #8a8784;
  --color-gray-600: #656360;
  --color-gray-700: #4b4a47;
  --color-gray-800: #31302e;
  --color-gray-900: #1f1d1b;

  --color-red: #E50051;
  --color-blue: #729eba;
  --color-beige: #f2f1ec;
  --color-green: #90ad8e;
}

h1 {
  color: var(--color-red);
}

h2 {
  color: var(--color-blue);
  line-height: 28px;
}

button, input[type="submit"], input[type="reset"] {
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

a {
  color: var(--color-gray-700); 
}

a:hover {
  color: var(--color-red);
}

ul {
  list-style-type: none;
}

/* table */
table {
  width: 80%;
  border-collapse: collapse;
  border: 1px solid #ddd;
  margin: 10px auto 0 auto;
}

th, td {
  border: 1px solid #ddd;
  padding: 8px;
}

th {
  background-color: #f2f2f2;
}

tr:hover {
  background-color: #f5f5f5;
}

tfoot td {
  font-weight: bold;
}

/* Responsive Styles  */
@media (max-width: 600px) {
  /* table */
  table {
    font-size: 12px;
  }
  th, td {
    padding: 6px;
  }
  
}