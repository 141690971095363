.ctaContainer {
  padding: 25px 15px 35px 15px;

}

.ctaContainer button {
  padding: 12px 20px;
  background-color: var(--color-green);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-transform: uppercase;
  transition: background-color 0.3s ease;
  margin: 30px 0 10px 0;
  text-align: center;
  text-decoration: none;
}

.ctaContainer button a {
  color: white;
  text-decoration: none;
}

@keyframes glowing {
  0% {
    background-color: var(--color-green);
    box-shadow: 0 0 5px var(--color-green);
  }

  50% {
    background-color: #6fa86c;
    box-shadow: 0 0 20px #6fa86c;
  }

  100% {
    background-color: #81c97d;
    box-shadow: 0 0 5px #81c97d;
  }
}

.ctaContainer button {
  animation: glowing 1300ms infinite;
}


.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.modal img {
  max-width: 90%;
  max-height: 90%;
  object-fit: contain;
}


.uploadedImages {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center; 
}

.imgDiv {
  display: inline-block; /* Ermöglicht die Anzeige der Bilder nebeneinander */
  width: 250px; /* Setzt die Breite der Bilder auf 300px */
  height: 250px;
  margin: 5px;
}
.imgDiv img {
  width: 100%; /* Ermöglicht eine responsive Anpassung der Bilder innerhalb des Containers */
  cursor: pointer;
}

.random {
  width: 100%;
}

.uploadedImages img {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}


 /* Media queries */
 @media (max-width: 768px) {
  .uploadedImages img {
    
  }
}