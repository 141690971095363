 /* navigation */

 .navigation {
   display: flex;
   position: fixed;
   justify-content: center;
   background-color: #729eba;
   padding: 10px 0;
   width: 100%;
   font-size: 16px;
   z-index: 10;
   top: 0;
 }

 .navigationToggle {
   display: none;
   font-size: 20px;
   cursor: pointer;
 }

 .navigationList {
   display: flex;
   list-style: none;
   margin: 0;
   padding: 0;
 }

 .navigationItem {
   margin: 0 15px;
   padding-top: 5px;
   padding-bottom: 5px;
 }

 .navigationLink {
   text-decoration: none;
   color: #fff;
   font-size: 18px;
   transition: color 0.3s;
 }

 .navigationLink:hover {
   color: #e4e1d5;
 }

 /* header */

 .headerContainer {
   position: relative;
   background-color: #fff;
 }

 .headerImg {
   width: 100%;
 }

 header {
   z-index: -1;
   padding-top: 20px;
 }

 .headerText {
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   font-size: 24px;
   line-height: 36px;
   color: #fff; 
   background: rgba(75, 74, 71, 0.4);
   padding: 20px;
 }

 .headerText .countdown{
    padding-top: 10px;
    padding-bottom: 12px;
 }
 .headerText .date {
    padding-bottom: 5px;
    padding-top: 10px;
 }
 /* Language switch */
 .flags {
   width: 20px;
   display: inline-block;
   margin: 0 5px;
 }

 /* Media queries */
 @media (max-width: 768px) {
    .headerContainer img {
      margin-top: 45px;
    }

   .headerText {
      position: relative;
      transform: none;
      font-size: 16px;
      line-height: 24px;
      color: var(--color-red);
      background: var(--color-beige);
      padding: 5px;
      top: -8px;
      left: 0;
   }

   .navigation {
     flex-direction: column;
     align-items: center;
   }

   .navigationToggle {
     display: block;
     font-size: 30px;
     padding: 10px 0 10px 0;
   }

   .navigationList {
     display: none;
     flex-direction: column;
     align-items: center;
     width: 100%;
     position: absolute;
     top: 50px;
     background-color: var(--color-blue);
     margin-top: -10px;
   }

   .activeNavigationList {
     display: flex;
   }

   .navigationItem {
     margin: 3px 0;
     padding: 5px 0; 
   }
 }