.highlightItem {
  text-align: center;
  flex-basis: calc(33.33% - 20px);
  margin: 0px 10px 20px 10px;
  box-sizing: border-box;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.highlightItem div {
  margin-top: 10px;
}

.highlightImage {
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  overflow: hidden;
}

.highlightImage img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}

a.highlightItem:hover .highlightImage img {
  filter: brightness(90%);
}

a.highlightItem:hover h3 {
  color: #729eba;
}

a.highlightItem {
  text-decoration: none;
  color: var(--color-gray-700);
  font-weight: normal;
}

/* groomsmen, round items */
.highlightItem.groomsmen .highlightImage img {
  position: static;
  width: 100%;
  height: 100%;
}

.highlightItem.groomsmen .highlightImage {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  margin: 0 auto 10px;
  padding-bottom: 0;
}

.highlightItem.groomsmen {
  flex-basis: calc(25% - 20px);
  margin-bottom: 20px;
}

.highlightItem.groomsmen div {
  padding-top: 0;
}