
/* in general */
main.imprint {
  margin: 80px auto 50px auto;
  font-size: 16px;
  width: 80%;
}

main.imprint h2 {
  padding: 15px 0 10px 0;
}

/* in general */
main.mainRegistration {
  width: 300px;
  margin: 80px auto 50px auto;
  font-size: 16px;
}

main.showData {
  width: 100%;
  margin: 80px auto 50px auto;
  font-size: 16px;
}

main.showData .registrationForm input {
  width: 300px;
}


main.mainRegistration h1 {
  margin-bottom: 20px;
}

/* logout when needed */
.logoutSpan {
  margin: 30px auto 0 auto;
  font-size: 16px;
}

.logoutSpan span {
  cursor: pointer;
  text-decoration: underline;
  color: var(--color-gray-700);
}

.logoutSpan span:hover {
  color: var(--color-red);
}

.error {
  color: var(--color-red);
}

.error {
  color: #fff;
  margin-top: 30px;
  background: var(--color-red);
  padding: 15px;
}

.success {
  color: #fff;
  margin-top: 30px;
  background: var(--color-green);
  padding: 15px;
}

.success .logoutSpan span {
  color: var(--color-red);
}

.registrationForm {
  padding: 2px;
}

.registrationForm h2 {
  padding-bottom: 10px;
}

.greetings {
  padding: 10px 0 10px 0;
}

.formContainer {
  margin-bottom: 25px;
}

.formGroup {
  margin: 5px 0 5px 0;
}

.formGroupHeadline {
  width: 100%;
  text-align: left;
}

.formGroupHeadline label small {
  line-height: 10px;
}

.formGroup.checkBoxes {
  margin: 5px 0 20px 0;
}

/* second part of the form */
.formContainer.formMoreInfos .formGroup {
  margin: 20px 0 10px 0;
}


.registrationForm label {
  display: block;
  margin-bottom: 5px;
}

.registrationForm .checkBoxes label {
  width: 50%;
  display: inline-block;
  text-align: left;
}

.registrationForm input {
  width: 100%;
  padding: 8px;
  border: 1px solid var(--color-blue);
  border-radius: 4px;
  background-color: #fff;
  font-size: 16px;
  color: var(--color-gray-700);
  margin-bottom: 4px;
}

.registrationForm input.fileUpload {
  border: none;
  text-align: center;
}

.registrationForm .checkBoxes input {
  width: 50%;
  display: inline-block;

}

.registrationForm .numberInput label {
  width: 70%;
  display: inline-block;
  text-align: left;
}

.registrationForm input[type="number"] {
  width: 30%;
  display: inline-block;
}

.registrationForm button {
  padding: 8px 15px;
  background-color: var(--color-green);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-transform: uppercase;
  transition: background-color 0.3s ease;
}

.registrationForm button:hover {
  background-color: var(--color-red);
}

.registrationForm textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid var(--color-blue);
  border-radius: 4px;
  resize: vertical;
  background-color: #fff;
  color: var(--color-gray-700);
}

/* images upload */ 

.uploadedImages {
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center; 
}

.imgDiv img {
  width: 100%; /* Ermöglicht eine responsive Anpassung der Bilder innerhalb des Containers */
  cursor: pointer;
}

.imgDiv {
  display: inline-block; /* Ermöglicht die Anzeige der Bilder nebeneinander */
  width: 120px; /* Setzt die Breite der Bilder auf 300px */
  height: 150px;
  margin: 5px;
  background: pink;
}

.uploadedImages img {
  width: 90%;
  margin: 5px; /* Füge Abstand zwischen den Bildern hinzu */
}


.loader {
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid var(--color-red);
  width: 50px;
  height: 50px;
  -webkit-animation: spin 1s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
  margin: 10px auto; /* Zentriere den Loader horizontal */
}

p.description {
  margin-top: 20px; 
  white-space: pre-wrap;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

