/* Highlight items */
.highlightContainer {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

/* Witness items */
.highlightContainer.groomsmen {
    flex-wrap: wrap;
}

section p {
    padding: 10px 0 0;
    white-space: pre-wrap;
}

section {
    padding: 20px 20px 10px 20px;   
    width: 100%;
}
section .faq {
    padding-bottom: 20px;
}
section:nth-child(odd) {
    background-color: #fff;
}

section:nth-child(even) {
    background-color: var(--color-beige);
}

section:nth-child(even) h2 {
    color: var(--color-red);
}

/* Media queries */
@media (max-width: 768px) {
    .highlightContainer {
        flex-direction: column;
        align-items: center;
    }

    .highlightItem {
        flex-basis: 100%;
        margin: 10px 0;
    }
}